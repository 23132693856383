import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import { store } from "./store/store.js";
import "@/assets/css/tailwind.css";
import "@/assets/scss/app.scss";
import ldiComponents from "@emtecpoc/componentlibrary";
import PrimeVue from "primevue/config";
import "primeflex/primeflex.css";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ScrollPanel from "primevue/scrollpanel";
import Dialog from "primevue/dialog";
import RadioButton from "primevue/radiobutton";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Skeleton from "primevue/skeleton";
import SelectButton from "primevue/selectbutton";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "vue-select/dist/vue-select.css";
import ToastService from "primevue/toastservice";
import vSelect from "vue-select";
import Tooltip from "primevue/tooltip";
import mitt from "mitt";
import ConfirmationService from 'primevue/confirmationservice';

const app = createApp(App);


app.component("InputText", InputText);
app.component("Button", Button);
app.component("ScrollPanel", ScrollPanel);
app.component("Dialog", Dialog);
app.component("RadioButton", RadioButton);
app.component("Checkbox", Checkbox);
app.component("Dropdown", Dropdown);
app.component("Calendar", Calendar);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Skeleton", Skeleton);
app.component("SelectButton", SelectButton);
app.component("v-select", vSelect);
app.use(store);
app.use(router);
app.use(ldiComponents);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);
app.directive("tooltip", Tooltip);
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.mount("#app");
