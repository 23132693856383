import axiosConfig from "@/api/axiosConfig.js";

const dataProvider = {
  
  async GetIsLockedAlready(loadid) {
    console.log(loadid)
    let result = await axiosConfig
        .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/GetIsLockedAlready`, {
            params: {
              loadid
            },
        })
        .then((res) => res.data);

    return result;
},

  async GetLoadLockedEmployee(loadid) {
    console.log(loadid)
    let result = await axiosConfig
        .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/GetLoadLockedEmployee`, {
            params: {
              loadid
            },
        })
        .then((res) => res.data);

    return result;
},
  async GetLTLBolCodes() {
    let result = await axiosConfig
        .get(`${process.env.VUE_APP_API_WEB_URL}/Master/getltlbolcodes`, {
            params: {
               
            },
        })
        .then((res) => res.data);

    return result;
},
  async edireadytobill(loadid) {
    let result = await axiosConfig
        .post(`${process.env.VUE_APP_API_WEB_URL}/edi/readytobill`, {},{
          params: {
            loadid
        },
        })
        .then((res) => res.data);
    return result;
  },
  async UpdateInOutLocations(postBody) {
    let result = await axiosConfig
        .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/updateinoutlocations`, postBody)
        .then((res) => res.data);
    return result;
  },
  
  async GetLoadLocationsInAndOut(loadLocId) {
    let result = await axiosConfig
        .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/getinoutlocations`, {
            params: {
              loadlocationid:loadLocId
            },
        })
        .then((res) => res.data);

    return result;
},
async IsLoadExists(loadId) {
  let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/IsLoadExist`, {
          params: {
            loadID:loadId
          },
      })
      .then((res) => res.data);

  return result;
},
  async getCityStateZipAutoComplete(CityName, StateName, PostalCode, CountryName) {
    let result = await axiosConfig
        .post(`${process.env.VUE_APP_API_WEB_URL}/Master/CityStateZipAutoComplete`, {
            CityName,
            StateName,
            PostalCode,
            CountryName
        })
        .then((res) => res.data);
    return result;
  },
  async GetLTLCustomerMargin(CustomerId) {
    let result = await axiosConfig
        .get(`${process.env.VUE_APP_API_WEB_URL}/LTLCustomer/GetLTLCustomerMargin`, {
            params: {
              CustomerId:CustomerId
            },
        })
        .then((res) => res.data);

    return result;
},
  async insertAckowledgement(ackBody) {

    let result = await axiosConfig
    .post(`${process.env.VUE_APP_API_WEB_URL}/Master/insertacknowledgement`,
    ackBody
    )
    .then((res) => res.data);
    
  return result;
  },
  async GetLTLLoadAccesorials() {
    let result = await axiosConfig
        .get(`${process.env.VUE_APP_API_WEB_URL}/LTLLoads/GetLTLLoadAccesorials`, {
            params: {
              
            },
        })
        .then((res) => res.data);

    return result;
},
  async GetLTLLoadTracking(shipmentid) {
    let result = await axiosConfig
        .get(`${process.env.VUE_APP_API_WEB_URL}/LTLLoads/getltltracking`, {
            params: {
              shipmentId: shipmentid
            },
        })
        .then((res) => res.data);

    return result;
},
  async GetLTLDeliveryReceipt(loadid) {
    let result = await axiosConfig
        .get(`${process.env.VUE_APP_API_WEB_URL}/LTLLoads/GetLTLDeliveryReceipt`, {
            params: {
              loadId: loadid
            },
        })
        .then((res) => res.data);

    return result;
},
async GetLTLShippingLabels(loadid) {
  let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/LTLLoads/GetLTLShippingLabels`, {
          params: {
            loadId: loadid
          },
      })
      .then((res) => res.data);

  return result;
},
  async GetLTLBol(loadid) {
    let result = await axiosConfig
        .get(`${process.env.VUE_APP_API_WEB_URL}/LTLLoads/GetLTLBol`, {
            params: {
              loadId: loadid
            },
        })
        .then((res) => res.data);

    return result;
},
  async GetLTLCustomerHazmatContact(customerid) {
    let result = await axiosConfig
        .get(`${process.env.VUE_APP_API_WEB_URL}/LTLCustomer/GetLTLCustomerHazmatContact`, {
            params: {
                customerId: customerid
            },
        })
        .then((res) => res.data);

    return result;
},
  async GetLTLRateQuoteDocument(ltlRateBody) {
    let result = await axiosConfig
        .post(`${process.env.VUE_APP_API_WEB_URL}/LTLLoads/LTLRateQuotePDF`,
            ltlRateBody
        )
        .then((res) => res.data);
    return result;
},
  async SaveAndTenderLTLLoad(ltlLoadPostBody,ltlTenderBody) {
    let result = await axiosConfig
        .post(`${process.env.VUE_APP_API_WEB_URL}/LTLLoads/saveandtenderLTLLoad`,
        {
          lTLLoadSavePost:ltlLoadPostBody,
          lTLTenderPostBody:ltlTenderBody
        }
      
        )
        .then((res) => res.data);
    return result;
},
async SaveLTLLoad(ltlLoadPostBody,ltlTenderBody) {
  let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/LTLLoads/saveLTLLoad`,
      {
        lTLLoadSavePost:ltlLoadPostBody,
        lTLTenderPostBody:ltlTenderBody
      }
      )
      .then((res) => res.data);
  return result;
},
    async GetCityStateByZip(zip, country) {
        let result = await axiosConfig
            .get(`${process.env.VUE_APP_API_WEB_URL}/Master/CityStateByZip`, {
                params: {
                    zip,
                    country
                },
            })
            .then((res) => res.data);

        return result;
    },
    async GetLTLCarrierBySCAC(scac) {
        let result = await axiosConfig
            .get(`${process.env.VUE_APP_API_WEB_URL}/LTLCarrier/GetCarrierBySCAC`, {
                params: {
                    SCAC: scac
                },
            })
            .then((res) => res.data);

        return result;
    },
    async GetLTLVolumeRateQuote(ltlRateBody) {
      let result = await axiosConfig
          .post(`${process.env.VUE_APP_API_WEB_URL}/LTLLoads/GetVLTLRateQuote`,
              ltlRateBody
          )
          .then((res) => res.data);
      return result;
  },
    async GetLTLRateQuote(ltlRateBody) {
        let result = await axiosConfig
            .post(`${process.env.VUE_APP_API_WEB_URL}/LTLLoads/LTLRateQuote`,
                ltlRateBody
            )
            .then((res) => res.data);
        return result;
    },
    async SaveLTLLoadNotes(ltlLoadNotePostBody) {
        let result = await axiosConfig
            .post(`${process.env.VUE_APP_API_WEB_URL}/LTLLoads/saveLTLLoadNote`,
                ltlLoadNotePostBody
            )
            .then((res) => res.data);
        return result;
    },
  
    async GetLTLCustomerNotes(customerId) {
        let result = await axiosConfig
            .get(`${process.env.VUE_APP_API_WEB_URL}/LTLCustomer/getCustomerNotes`, {
                params: {
                    customerId: customerId
                },
            })
            .then((res) => res.data);

        return result;
    },
    async GetLtlLoad(loadId) {
        let result = await axiosConfig
            .get(`${process.env.VUE_APP_API_WEB_URL}/LTLLoads/getLTLLoad`, {
                params: {
                    loadId: loadId
                },
            })
            .then((res) => res.data);

        return result;
    },
    async GetLTLCustomerList() {
        let result = await axiosConfig
            .get(`${process.env.VUE_APP_API_WEB_URL}/LTLCustomer/getLTLCustomersList`, {
                params: {
                },
            })
            .then((res) => res.data);

        return result;
    },
    async InsertLocations(customerLocationPostBody) {
        let result = await axiosConfig
            .post(`${process.env.VUE_APP_API_WEB_URL}/Customer/insertCustomerLocation`, 
                customerLocationPostBody
            )
            .then((res) => res.data);
        return result;
    },
    async loginToITS(employeeID, portalID, userName, password) {
        let result = await axiosConfig
            .post(`${process.env.VUE_APP_API_WEB_URL}/posting/gettruckstoprefreshtoken`
                , {
                    Username: userName,
                    Password: password,
                    EmployeeID: employeeID,
                    PortalID: portalID,
                })
            .then((res) => res.data);
        return result;
    },
    async getCheckITSToken() {
        let result = await axiosConfig
            .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/checkitstoken`)
            .then((res) => res.data);
        return result;
    },

  async deleteDocuments(documentIds) {
    let result = await axiosConfig
      .delete(`${process.env.VUE_APP_API_WEB_URL}/Carrier/carrierdocuments`, {
        data: {
          DocumentIds: documentIds,
        },
      })
      .then((res) => res.data);
    return result;
  },

  async setEmployeeSettings(keycode, settingValue) {
    let result = await axiosConfig
      .put(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/saveEmployeeSetting`, {
        KeyCode: keycode,
        SettingValue: settingValue,
      })
      .then((res) => res.data);
    return result;
  },

  async getColumnSetting(keycode) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/getEmployeeSetting`, {
        params: {
          KeyCode: keycode,
        },
      })
      .then((res) => res.data);

    return result;
  },
  async getChartData(TimezoneOffset) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/myLoadGraph`, {
        params: {
          TimezoneOffset: TimezoneOffset,
        },
      })
      .then((res) => res.data);

    return result;
  },
  async getNotificationCount() {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/Notification/notificationcount`)
      .then((res) => res.data);

    return result;
  },
  async getNotifications(pageNumber, pageSize, severityLevel) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/Notification/notications`, {
        params: {
          pageNumber: pageNumber,
          pageSize: pageSize,
          severity: severityLevel,
        },
      })
      .then((res) => res.data);
    return result;
  },
  async updateNotifications(notificationids, status) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Notification/updateNotification`, {
        notificationID: notificationids,
        status: status,
      })
      .then((res) => res.data);
    return result;
  },
  async updateCarrierFallOff(carrierID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/updateCarrierFallOff`, {
        carrierID: carrierID,
      })
      .then((res) => res.data);
    return result;
  },
  async getCustomers(accOnhold = 0) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/customerSelectionFields`
        , {
          params: {
            AccountOnHold: accOnhold
          },
        })
      .then((res) => res.data);
    return result;
  },
  async getCarrierSelectionList(filterOnHold = true, uspsCarriersOnly = false) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/carrierSelectionFields`
        , {
          params: {
            FilterOnHold: filterOnHold,
            USPSCarriersOnly: uspsCarriersOnly
          },
        })
      .then((res) => res.data);
    return result;
  },
  async getCarrierNames(filterOnHold = true, uspsCarriersOnly = false) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/carrierSelectionNames`
        , {
          params: {
            FilterOnHold: filterOnHold,
            USPSCarriersOnly: uspsCarriersOnly
          },
        })
      .then((res) => res.data);
    return result;
  },
  async getCustomerNotes(custID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Customer/getCustomerNotes`
        , {
          CustomerID: custID

        })
      .then((res) => res.data);
    return result;
  },
  async getCustomerLocations(customerID, locTypeID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Customer/getCustomerLocation`
        , {
          LocationName: null,
          Address: null,
          City: null,
          State: null,
          PostalCode: null,
          CustomerID: customerID,
          LocTypeID: locTypeID
        })
      .then((res) => res.data);
    return result;
  },
  async getEquipmentTypes() {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/Master/equipments`)
      .then((res) => res.data);
    return result;
  },
  async SaveCarrierNotes(carrierID,notes) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/saveCarrierNotes`, {
        CarrierID: carrierID,
        Note: notes
      })
      .then((res) => res.data);
    return result;
  },
  async getCityState(CustomerID, CityName, StateName, LocTypeID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Master/CityStateNew`, {
        CustomerID: CustomerID,
        CityName: CityName,
        StateName: StateName,
        LocTypeID: LocTypeID,
      })
      .then((res) => res.data);
    return result;
    },
    async getCityStateZip(CityName, StateName, PostalCode, CountryName) {
        let result = await axiosConfig
            .post(`${process.env.VUE_APP_API_WEB_URL}/Master/CityStateZip`, {
                CityName,
                StateName,
                PostalCode,
                CountryName
            })
            .then((res) => res.data);
        return result;
    },
  async saveCarrierLoad(saveCarrierLoadData) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/SourceLoad/SaveCarrier`, {
        CarrierPickID: parseInt(saveCarrierLoadData.CarrierPickID),
        CarrierID: parseInt(saveCarrierLoadData.CarrierID),
        LoadID: parseInt(saveCarrierLoadData.LoadID),
        EmployeeID: 0,
        Primary: saveCarrierLoadData.Primary,
        Dispatcher: saveCarrierLoadData.Dispatcher,
        ELDEnabled: saveCarrierLoadData.ELDEnabled,
        AssistingPortalID: parseInt(saveCarrierLoadData.AssistingPortalID),
        AssistingEmployeeID: parseInt(saveCarrierLoadData.AssistingEmployeeID),
      })
      .then((res) => res.data);
    return result;
  },
  async saveLoadCreateALoad(saveInitialLoad) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/saveLoadCreateALoad`, {
        LoadType: parseInt(saveInitialLoad.LoadType),
        CustomerID: saveInitialLoad.CustomerID,
        CustomerName: saveInitialLoad.CustomerName,
        PickupCity: saveInitialLoad.PickupCity,
        PickupState: saveInitialLoad.PickupState,
        PickupDate: saveInitialLoad.PickupDate,
        PickupStartTime: saveInitialLoad.PickupStartTime,
        PickupEndTime: saveInitialLoad.PickupEndTime,
        DestinationCity: saveInitialLoad.DestinationCity,
        DestinationState: saveInitialLoad.DestinationState,
        DestinationDate: saveInitialLoad.DestinationDate,
        DestinationStartTime: saveInitialLoad.DestinationStartTime,
        DestinationEndTime: saveInitialLoad.DestinationEndTime,
        Commodity: saveInitialLoad.Commodity,
        PickupWeight: parseInt(saveInitialLoad.PickupWeight),
        Mileage: parseInt(saveInitialLoad.Mileage),
        EquipmentID: saveInitialLoad.EquipmentID,
        EquipMinSize: parseInt(saveInitialLoad.EquipMinSize),
        TeamService: saveInitialLoad.TeamService,
        PartialLoad: saveInitialLoad.PartialLoad,
        PayRange: saveInitialLoad.PayRange,
        Comments: saveInitialLoad.Comments,
        PostRate: saveInitialLoad.PostRate,
        PostComments: saveInitialLoad.PostComments,
        DATEnabled: saveInitialLoad.DATEnabled,
        TruckstopEnabled: saveInitialLoad.TruckstopEnabled,
        PEPEnabled: saveInitialLoad.PEPEnabled,
        NetworkLoad: saveInitialLoad.NetworkLoad,
        ActionType: saveInitialLoad.ActionType,
        TimezoneOffset: saveInitialLoad.TimezoneOffset,
        LoadDate: saveInitialLoad.LoadDate
      })
      .then((res) => res.data);
    return result;
  },
  async deleteDriver(driverID, carrierID) {
    let result = await axiosConfig
      .delete(`${process.env.VUE_APP_API_WEB_URL}/Carrier/DeleteCarrierDriver`, {
        data: {
          carrierDriverID: parseInt(driverID),
          CarrierID: parseInt(carrierID),
        },
      })
      .then((res) => res.data);
    return result;
  },
  async saveCarrierDriver(saveCarrierDriver) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/saveUpdateCarrierDriver`, {
        DriverID: parseInt(saveCarrierDriver.DriverID),
        FirstName: saveCarrierDriver.FirstName,
        LastName: saveCarrierDriver.LastName,
        PhoneNumber: saveCarrierDriver.PhoneNumber,
        TractorNo: saveCarrierDriver.TractorNo,
        TrailerNo: saveCarrierDriver.TrailerNo,
        Notes: saveCarrierDriver.Notes,
        CarrierID: parseInt(saveCarrierDriver.CarrierID),
        PortalID: parseInt(saveCarrierDriver.PortalID),
      })
      .then((res) => res.data);
    return result;
  },
  async getSourceLoadData(id) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/SourceLoad/SourceLoadDetails`, {
        params: {
          LoadID: id,
        },
      })
      .then((res) => res.data);
    return result;
  },
  async getCarrierProfileGeneralTabDetails(id) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/carrierProfileGeneralTabInfo`, {
        CarrierID: id,
      })
      .then((res) => res.data);
    return result;
  },
  async getCarrierSafetyTabDetails(id, mc, usdot) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/getCarrierSafetyProfile`, {
        CarrierID: id,
        CarrierMCNum: mc,
        USDOTNumber: usdot,
      })
      .then((res) => res.data);
    return result;
  },
  async getCarrierInsuranceTabDetails(id, mc, usdot) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/carrier-insuranceInformation`, {
        CarrierID: id,
        CarrierMCNum: mc,
        USDOTNumber: usdot,
      })
      .then((res) => res.data);
    return result;
  },
  async getCarrierProfileTabDetails(id) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/carrier-profileInformation`, {
        CarrierID: id,
      })
      .then((res) => res.data);
    return result;
  },
  // async getCarrierProfileHistoryTabInfo(params) {
  //   let result = await axiosConfig
  //     .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/carrierProfileHistoryTabInfo`, {
  //       CarrierID: params.lazyEvent.CarrierID,
  //       TimezoneOffset: params.lazyEvent.TimezoneOffset,
  //       First: params.lazyEvent.first,
  //       Page: params.lazyEvent.page,
  //       Rows: params.lazyEvent.rows,
  //       SortOrder:
  //         params.lazyEvent.sortOrder == null ? -1 : params.lazyEvent.sortOrder,
  //       SortField:
  //         params.lazyEvent.sortField == null || params.lazyEvent.sortField == ""
  //           ? "PickupDate"
  //           : params.lazyEvent.sortField,
  //     })
  //     .then((res) => res.data);
  //   return result;
  // },
  async getAllHistoryFilterOptions(id, calName) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/Carrier/carrierlocations`, {
        params: {
          CarrierID: id,
          tabID: calName,
          timeZoneOffset: new Date().getTimezoneOffset() * -1
        },
      })
      .then((res) => res.data);
    return result;
  },

  async getCarrierProfileDocumentTabInfo(params) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/carrierdocumentdetails`, {
        CarrierID: params.lazyEvent.CarrierID,
        DocumentGroup: params.lazyEvent.DocumentGroup,
      })
      .then((res) => res.data);
    return result;
  },
  async getCarrierDetails(id) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/getCarrierProfile`, {
        CarrierID: id,
        CarrierMCNum: "",
        USDOTNumber: "",
      })
      .then((res) => res.data);
    return result;
  },
  async lookupCarrier(lookupFieldAndValue) {
    const defaults = {
      CarrierMCNum: null,
      USDOTNumber: null,
      DOTNumber: null
    };
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/lookupCarrier`, {
        ...defaults,
        ...lookupFieldAndValue
      })
      .then((res) => res.data);
    return result;
  },
  async getLineItemDefinitions() {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Master/lineitemdefinitions`)
      .then((res) => res.data);
    return result;
  },
   async getTrackandtrace(loadId){
    let result = await axiosConfig
    .get(`${process.env.VUE_APP_API_WEB_URL}/Tracking/gettrackandtrace/${loadId}`)
    .then((res) => res.data);
    return result;
   },
   
   async cancelTracking(loadID) {
    let result = await axiosConfig 
    .post(`${process.env.VUE_APP_API_WEB_URL}/Tracking/stoptracking/${loadID}`)
    .then((res) => res);

    return result;
   },
   async deleteSubscription(LoadTrackingSubscriptionID) {
    let result = await axiosConfig
    .delete(`${process.env.VUE_APP_API_WEB_URL}/Tracking/deletesubscription/${LoadTrackingSubscriptionID}`)
    .then((res) => res);

    return result;
   },
   async saveSubscription(subscription)
   {
    subscription.forEach(element => {
        if(element.LoadTrackingSubscriptionID==0)
        {
          let result = axiosConfig
          .post(`${process.env.VUE_APP_API_WEB_URL}/Tracking/insertsubscription`, {
            LoadTrackingSubscriptionID: parseInt(element.LoadTrackingSubscriptionID),
            LoadID: parseInt(element.LoadID),
            EmailAddress: element.EmailAddress,
            NotificationFrequency: parseInt(element.NotificationFrequency),
            IsIndividual: element.IsIndividual,
          })
          .then((res) => res);
        return result;
        }
        else{
         //update
         let result = axiosConfig
         .put(`${process.env.VUE_APP_API_WEB_URL}/Tracking/updatesubscription`, {
           LoadTrackingSubscriptionID: parseInt(element.LoadTrackingSubscriptionID),
           LoadID: parseInt(element.LoadID),
           EmailAddress: element.EmailAddress,
           NotificationFrequency: parseInt(element.NotificationFrequency),
           IsIndividual: element.IsIndividual,
         })
         .then((res) => res);
       return result;
        }
      });
   },
   async getSettingConfigData(loadId) {
    let result = await axiosConfig
    .get(`${process.env.VUE_APP_API_WEB_URL}/Tracking/getsubscriptions/${loadId}`)
    .then((res) => res.data);
    return result;
  },
  async getCarrierFilter(filterData, filterName, loadType) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/SourceLoad/sourceLoadFilterData`, {
        params: {
          filterData: filterData,
          filterName: filterName,
          loadType: loadType
        },
      })
      .then((res) => res.data);
    return result;
  },
  async getFavoriteCarrierFilter(filterData, loadType) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/SourceLoad/sourceLoadFavoriteFilterData`, {
        params: {
          filterData: filterData,
          loadType: loadType
        },
      })
      .then((res) => res.data);
    return result;
  },
  async getCarrierPerformance(carrierID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/carrier/getCarrierPerformance`, {
        carrierID: carrierID,
      })
      .then((res) => res.data);
    return result;
  },
  async getCarrierLanes(carrierID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/getCarrierLanes`, {
        carrierID: carrierID,
      })
      .then((res) => res.data);
    return result;
  },
  async getCarrierContact(carrierID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/getCarrierContacts`, {
        carrierID: carrierID,
      })
      .then((res) => res.data);
    return result;
  },
  async getCarrierOffice(carrierID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/getCarrierOffices`, {
        carrierID: carrierID,
      })
      .then((res) => res.data);
    return result;
  },
  async getCarrierNotes(carrierID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/carrier/getCarrierNotes`, {
        CarrierID: carrierID,
      })
      .then((res) => res.data);
    return result;
  },
  async getDriversData(carrierID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/carrier/getCarrierDrivers`, {
        carrierID: carrierID,
      })
      .then((res) => res.data);
    return result;
  },
  async getLoadBoardAccess() {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/loadBoardAccess`)
      .then((res) => res.data);
    return result;
  },
  async deleteMyLoad(empid, loadid) {
    let result = await axiosConfig
      .delete(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/deleteAvailableLoads`, {
        data: {
          EmployeeID: empid,
          LoadID: loadid,
        },
      })
      .then((res) => res.data);
    return result;
  },
  async deleteCheckedLoads(empid, loadids) {
    let result = await axiosConfig
      .delete(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/deleteSelectedAvailableLoads`, {
        data: {
          EmployeeID: empid,
          LoadID: loadids,
        },
      })
      .then((res) => res.data);
    return result;
  },
  async getTrackLoadMilege(
    pickupcity,
    pickupstate,
    destinationcity,
    destinationstate
  ) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/trackLoadMilege`, {
        pickupLocations: [
          {
            City: pickupcity,
            State: pickupstate,
          },
        ],
        dropLocations: [
          {
            City: destinationcity,
            State: destinationstate,
          },
        ],
      })
      .then((res) => res.data);
    return result;
  },
  async getMultipleTrackLoadMilege(
    pickupLocations,
    dropLocations
  ) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/trackLoadMilege`, {
        pickupLocations,
        dropLocations
      })
      .then((res) => res.data);
    return result;
  },
  async uploadDocs(
    agentDocsData
  ) {

    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/uploadDocs`,
        agentDocsData
      )
      .then((res) => res.data);
    return result;
  },
  async getSourceLoadRateCost(
    pickupcity,
    pickupstate,
    destinationcity,
    destinationstate,
    equipementIDs
  ) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/SourceLoad/getSourceLoadRateCost`, {
        PickupCity: pickupcity,
        PickupState: pickupstate,
        DestinationCity: destinationcity,
        DestinationState: destinationstate,
        EquipmentIDs: equipementIDs,
      })
      .then((res) => res.data);
    return result;
  },
  async setFavourite(carrierid, isfavourite) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/carrier/AddOrRemoveCarrierFromFavorite`, {
        CarrierID: carrierid,
        IsFavoriteCarrier: isfavourite,
      })
      .then((res) => res.data);
    return result;
  },
  async createHelpDeskTicket(carrierid, MCNum, USDot, ticket) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/carrier/HelpDeskTicket`, {
        CarrierID: parseInt(carrierid),
        CarrierMCNum: MCNum,
        USDOTNumber: USDot,
        ticketType: ticket,
      })
      .then((res) => res.data);
    return result;
  },
  async GetEditLoadDetails(loadID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/getCurrentLoad`, {
        LoadID: loadID
      })
      .then((res) => res.data);
    return result;
  },
  async updateLoadLock(
    LoadID,
    LockedByID,
    LockedByUser,
    LockLoad,
    isFromLeaving
  ) {
    let result = axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/updateLoadLock`, {
        LoadID,
        LockedByID,
        LockedByUser,
        LockLoad,
        isFromLeaving
      })
      .then((res) => res.data);

    return result;
  },

  async SelectCarrier(carrierID) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/SourceLoad/SelectCarrier`, {
        params: {
          CarrierId: carrierID,
        },
      })
      .then((res) => res.data);

    return result;
  },

  async deleteSelectedLDIDocs(DocsIDs) {

    console.log(DocsIDs);

    let result = await axiosConfig
      .delete(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/deleteDocuments`,
        {
          data: {
            deleteDocDetails: DocsIDs
          }
        }
      )
      .then((res) => res.data);
    return result;
  },

  async sendToHubTran(DocsIDs) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/sendToHubTran`, {
        DocumentOwnerID: DocsIDs.DocumentOwnerID,
        DocumentFrom: DocsIDs.DocumentFrom,
        getDocumentDetails: DocsIDs.getDocumentDetails
      }
      )
      .then((res) => res.data);
    return result;
  },

  async GetLDIAgentDocuments(DocumentGroupID, DocumentOwnerID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/getLoadDocuments`, {
        DocumentGroupID: DocumentGroupID,
        DocumentOwnerID: DocumentOwnerID
      })
      .then((res) => res.data);
    return result;
  },

  async GetLoadNotes(loadID) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/loadnotes/getLoadNotes`, {
        params: {
          LoadID: loadID,
        },
      })
      .then((res) => res.data);
    return result;
  },

  async getPODUnsatReasons() {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/getPODUnsatReasons`, {})
      .then((res) => res.data);
    return result;
  },
  async getRateConFormData(loadID) {

    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/getRateConFormData`, {
        params: {
          LoadID: loadID,
        },
      })
      .then((res) => res.data);
    return result;
  },

  async manuallySignRateConfirmation(rateConfirmationID) {

    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/manuallySignRateConfirmation`, {
        params: {
          RateConfirmationID: rateConfirmationID,
        }
      })
      .then((res) => res.data);
    return result;
  },

  async sendForSigning(request) {

    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/sendForSigning`,
        request
      )
      .then((res) => res.data);
    return result;
  },

  async getRateConReportData(rateConReportRequest, bolDetailRequest) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/getRateConBOLReport`, {
        rateConReportRequest: rateConReportRequest,
        bolDetailRequest: bolDetailRequest
      })
      .then((res) => res.data);
    return result;
  },

  async SaveLoadBookEdit(loadDetailRequest) {
    console.log(loadDetailRequest);
    let result = await axiosConfig
    .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/saveLoadBookEdit`,
        loadDetailRequest
      )
      .then((res) => res.data);
    return result;
  },

  async SaveCarrierSafetyInfo(carrierSafetyRequest) {  
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/saveCarrierSafetyInfo`, 
        carrierSafetyRequest
      )
      .then((res) => res.data);
    return result;
  },

  async SaveCarrierProfileInfo(carrierProfileRequest) {  
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/saveCarrierProfileInformation`, 
      carrierProfileRequest
      )
      .then((res) => res.data);
    return result;
  },

  async saveLoadNotes(loadID, notes, commodity, isEmailChecked, emailID, localeDateTime) {

    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/loadnotes/saveLoadNotes`, {
        LoadID: loadID,
        Note: notes,
        Commodity: commodity,
        IsEmailChecked: isEmailChecked,
        EmailID: emailID,
        LocaleDateTime: localeDateTime
      })
      .then((res) => res.data);
    return result;
  },

  async startTracking(loadID) {
    let result = await axiosConfig 
    .post(`${process.env.VUE_APP_API_WEB_URL}/Tracking/starttracking/${loadID}`)
    .then((res) => res);
    return result;
  },
  
  async updateTracking(loadID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Tracking/updatetracking/${loadID}`)
      .then((res) => res);
    return result;
  },

  async GetTrackingSettings(loadID) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/Tracking/gettrackingsettings/${loadID}`)
      .then((res) => res.data);
    return result;
  },

  async saveTrackingSetting(TrackingSettingsRequest) {
    let trackingSettings = TrackingSettingsRequest;
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Tracking/inserttrackingsettings`,
        trackingSettings)
      .then((res) => res);
      console.log(result);
    return result;
  },

  async updateTrackingSetting(TrackingSettingsRequest) {
    let trackingSettings = TrackingSettingsRequest;
    let result = await axiosConfig
      .put(`${process.env.VUE_APP_API_WEB_URL}/Tracking/updatetrackingsettings`,
        trackingSettings)
      .then((res) => res);
      console.log(result);
    return result;
  },
  async getNetworkLoadInfo(PortalID , IsRetrieveEmployees){
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/getNetworkLoadInfo`, {
        PortalID: PortalID,
        IsRetrieveEmployees: IsRetrieveEmployees
      })
      .then((res) => res.data);
    return result;
  },
  async GetCOMCheckData(loadID) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/getComcheck`, {
        params: {
          LoadID: loadID,
        },
      })
      .then((res) => res.data);
    return result;
  },

  async GetBOLReport(BOLDetailRequest) {
    console.log(BOLDetailRequest);
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/GetBOLReport`,
        BOLDetailRequest
      )
      .then((res) => res.data);
    return result;
  },

  async copyLoad(CopyLoadReq) {
    console.log(CopyLoadReq);
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/copyLoad`,
        CopyLoadReq
      )
      .then((res) => res.data);
    return result;
  },

  
  async retrieveDocument(DocumentPath){
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/retrieveDocByPath`,
        DocumentPath
      )
      .then((res) => res.data)
      .catch(
        function (error) {
          console.log(error);
          return ""
        }
      )

    return result;
  },

  async moveDocuments(DocumentIds) {

    console.log(DocumentIds);
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/moveDocuments`, {
        DocumentID: DocumentIds
      }
      )
      .then((res) => res.data);
    return result;
  },
  async getRoutingDetails(milesRoute) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/milesRoute`,
        milesRoute
      )
      .then((res) => res.data);
    return result;
  },

  async sendEmail(request) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Email/SendEmail`,
        request
      )
      .then((res) => res.data);
    return result;
  },

  async sendEmailWithDocuments(request) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Email/SendEmailWithDocuments`,
        request
      )
      .then((res) => res.data);
    return result;
  },

  async sendEmailWithAttachments(request) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Email/SendEmailWithBase64Attachments`,
        request
      )
      .then((res) => res.data);
    return result;
  },
  async sendCSSEmailDetail(params) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/cssSendEmailDetail`, {
          LoadID: '',
          Origin: params.Origin,
          PickupDate: params.PickupDate,
          Destination: params.Destination,
          DeliveryDate: params.DropDate,
          Commodity: params.Commodity,
          Weight: params.Weight,
          EquipmentSize: params.Size,
          EquipmentType: params.Equipment
        
      }
      )
      .then((res) => res.data);
    return result;
  },

  async sendEmailDetail(ownerID, employeeID) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/sendEmailDetail`, {
        OwnerID: ownerID,
        EmployeeID: employeeID
      }
      )
      .then((res) => res.data);
    return result;
  },
  async sendPacket(request) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/sendPacket`,
        request
      )
      .then((res) => res.data);
    return result;
  },
  async sendPDFPacket(request) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/sendPDFPacket`,
        request
      )
      .then((res) => res.data);
    return result;
  },
  async acknowledgeBillingInstructions(request) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Invoice/acknowledgeBillingInstructions`,
        request
      )
      .then((res) => res.data);
    return result;
  },
  async getEDI204LoadTenders() {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/EDI/getTenders`)
      .then((res) => res.data);
    return await result;
  },
  async getEDI204LoadTenderDetails(params) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/EDI/getTenderDetails`, {
        params
      })
      .then((res) => res.data);
    return result;
  },
  async acceptEDI204LoadTender(params) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/EDI/AcceptTender`,
        params
      )
      .then((res) => res.data);
    return result;
  },
  async declineEDI204LoadTender(params) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/EDI/DeclineTender`,
        params
      )
      .then((res) => res.data);
    return result;
  },
  async getSDResons() {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/Trackloads/GetShippingDelayReasons`)
      .then((res) => res.data);
    return await result;
  },
  async saveShippingDelay(loadID, createdBy, loadLocID, shippingDelayReasonID, newETA, comments) {
    let result = await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Trackloads/SaveShippingDelay`, {
        LoadID: loadID,
        CreatedBy: createdBy,
        LoadLocID: loadLocID,
        ShippingDelayReasonID: shippingDelayReasonID,
        NewETA: newETA,
        Comments: comments
      })
      .then((res) => res.data);
    return result;
  },
  async getLoadDrops(loadID) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/Trackloads/getLoadDrop`, {
        params: {
          LoadID: loadID,
        },
      })
      .then((res) => res.data);
    return await result;
  },
  async getFTLEditSettings() {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/getFTLEditSettings`)
      .then((res) => res.data);
    return result;
  },
  async GetAllFeaturesByUser() {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_WEB_URL}/features/GetAllFeaturesByUser`
        , {
          params: {
            //ldi5 applicationid is 9 in feature management.
            applicationid: 9
          },
        })
      .then((res) => res.data);
    return result;
  },
  async getDormantCustomers(data) {
    let result = await axiosConfig
    .get(process.env.VUE_APP_API_WEB_URL + "/dormantcustomers/getdormantcustomers/",data);
    return result;
  },
  async getCustomersTracking(data) {
    let result = await axiosConfig
    .get(process.env.VUE_APP_API_WEB_URL + "/dormantcustomers/getdormantcustomerstracking/",data);
    return result;
  },
  async updatedormantcustomerinactive(data) {
    let result = await axiosConfig
    .post(process.env.VUE_APP_API_WEB_URL + "/dormantcustomers/updatedormantcustomerinactive/",data);
    return result;
  },
  async getActivePortals(data) {
    let result = await axiosConfig
    .get(process.env.VUE_APP_API_WEB_URL + "/dormantcustomers/getactiveportals/",data);
    return result;
  },
  async unassignagent(data) {
    let result = await axiosConfig
    .post(process.env.VUE_APP_API_WEB_URL + "/dormantcustomers/unassignagent/",data);
    return result;
  },
  async assignagent(data) {
    let result = await axiosConfig
    .post(process.env.VUE_APP_API_WEB_URL + "/dormantcustomers/assignagent/",data);
    return result;
  },
  async getdormantcustomerdashBoard(data) {
    let result = await axiosConfig
    .get(process.env.VUE_APP_API_WEB_URL + "/dormantcustomers/getdormantcustomerdashBoard/",data);
    return result;
  },

  async getallcustomercontacts(data) {
    let result = await axiosConfig
    .get(process.env.VUE_APP_API_WEB_URL + "/dormantcustomers/getallcustomercontacts/" + data);
    return result;
  },
  async getdormantcustomernotes(data) {
    let result = await axiosConfig
    .get(process.env.VUE_APP_API_WEB_URL + "/dormantcustomers/getdormantcustomernotes/"+ data);
    return result;
  },
  async insertdormantcustomernotes(data) {
    let result = await axiosConfig
    .post(process.env.VUE_APP_API_WEB_URL + "/dormantcustomers/insertdormantcustomernotes/", data);
    return result;
  },
  async getdormantcustomerreport(customerID,datatype,returntype) 
  {
    let result = await axiosConfig
    .get(process.env.VUE_APP_API_WEB_URL + "/dormantcustomers/getdormantcustomerreport?customerID="+ customerID + "&reportReturnType=" + datatype + "&reportReturnDataType=" + returntype);
    return result;
  },
  async  SendDormantCustomerReport(data) {
    let result = await axiosConfig
    .post(process.env.VUE_APP_API_WEB_URL + "/dormantcustomers/senddormantcustomerreport/",data);
    return result;
  },
};

export { dataProvider };
