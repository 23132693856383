export {
  convertToMultipleOf15,
  parseLdiStartAndEndTime,
  handleMeridiem
}

function convertToMultipleOf15(time)
{
    let date = new Date('2000-01-01 ' + time);
    let minutes = date.getMinutes()
    let hoursBefore = date.getHours();
    const quotient = minutes / 15;
    const roundedQuotient = Math.round(quotient);
    const nearestMultipleOf15 = roundedQuotient * 15;
    
    date.setMinutes(nearestMultipleOf15);

    // Format the hours in 12-hour format
    let hours = date.getHours();
    if(hours != hoursBefore)
    {
      hours = hoursBefore
    }
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format

    // Construct the formatted time string
    var formatedTime = `${hours}:${date.getMinutes().toString().padStart(2, '0')} ${period}`;
    
    let wasRounded = minutes%15 !== 0;

    return [formatedTime, wasRounded];
}

function parseLdiStartAndEndTime(inputTime, dateTimeOut = true) {
  // Parse start/end times in the LDI5 format, will attempt to resolve others or will return null for both start and end time
  // const re = /^(?<startHr>[01]\d|2[0-3])(:)?(?<startMin>[0-5]\d)( ?-?(To)? ?)?((?<endHr>[01]\d|2[0-3])(:)?(?<endMin>[0-5]\d))?$/i; // Saving this simplified regex for revert if needed

  const re = /^(?<startHr>[01]\d|2[0-3]|\d)(:)?(?<startMin>[0-5]\d)?( ?(?<startMeridiem>am|pm|a|p)? ?-?(To)? ?)?((?<endHr>[01]\d|2[0-3]|\d)(:)?(?<endMin>[0-5]\d)? ?(?<endMeridiem>am|pm|a|p)?)?$/i;

  let parsed = inputTime.replaceAll('.', '').match(re)?.groups ?? {};

  let startHr = parsed.startHr;
  let startMin = parsed.startMin ?? '00';
  let endHr = parsed.endHr;
  let endMin = parsed.endMin ?? '00';

  if (startHr && parsed.startMeridiem) {
    startHr = handleMeridiem(startHr, parsed.startMeridiem)
  }
  if (endHr && parsed.endMeridiem) {
    endHr = handleMeridiem(endHr, parsed.endMeridiem)
  }

  // All hours must be two digits
  if (startHr?.length === 1) {
    startHr = '0' + startHr
  }
  if (endHr?.length === 1) {
    endHr = '0' + endHr
  }

  if (dateTimeOut) {
    const parsedToTime = (hr, min) => hr && min ? new Date(2000, 1, 1, parseInt(hr, 10), parseInt(min, 10)) : null;
    return [ parsedToTime(startHr, startMin), parsedToTime(endHr, endMin) ];
  } else {
    const parsedToStr = (hr, min) => hr && min ? hr + min : null;
    return [ parsedToStr(startHr, startMin), parsedToStr(endHr, endMin) ];
  }
}

function handleMeridiem(hr, mer) {
  let hrInt = parseInt(hr, 10);
  let lowerMer = mer.toLowerCase()
  if ((hrInt < 12 && (lowerMer === 'p' || lowerMer === 'pm')) || (hrInt === 12 && (lowerMer === 'a' || lowerMer === 'am'))) {
    return String((hrInt + 12) % 24)
  } else {
    return hr
  }
}